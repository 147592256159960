<template>
  <div class="container">
    <edit-header
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew"
      entity="weeklyMenu"></edit-header>
    <alert
      v-if="!isAllRecipes && !loading"
      :can-be-closed="false"
      type="warning"
      message="Publishing/Submitting is disabled. You should choose atleast 20 recipes">
    </alert>
    <alert
      v-show="!readyToBePublished"
      :can-be-closed="false"
      type="warning"
      message="Publishing/Submitting is disabled. All recipes should be in status Ready to be drafted">
    </alert>
    <dimmer :active="loading">
      <form
        v-disable-all="!can(uiPermissions.MENU_SCHEDULE_UPDATE)"
        ref="form"
        class="validation"
        novalidate
        @submit.prevent="submit">
        <div class="row row-cards row-deck">
          <div data-test="card-weekly-menu" class="col-md-4">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Weekly menu</h3>
                <div class="card-options">
                  <label data-test="toggle-published" class="custom-switch m-0">
                    <input
                      v-model="item.published"
                      :disabled="isSwitcherDisabled"
                      type="checkbox"
                      class="custom-switch-input"
                      @change="switchPublished(item)">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">Published</span>
                  </label>
                </div>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label class="form-label">Start date</label>
                  <div class="input-group">
                    <input
                      v-model="startDateModel"
                      type="date"
                      class="form-control"
                      required
                      readonly>
                    <div class="input-group-append">
                      <span class="input-group-text">{{moment(startDateModel).format('ddd')}}</span>
                    </div>
                  </div>
                  <div class="small mt-1">{{weekName(this.startDate)}}</div>
                  <div v-if="!startDateIsThursday" class="invalid-feedback d-block">Start date must be on a Thursday</div>
                </div>
              </div>
            </div>
          </div>
          <div data-test="card-recipes" class="col-md-8">
            <weekly-menus-recipe-list-card :items="item.recipes">
              <template #header>Recipes</template>
            </weekly-menus-recipe-list-card>
          </div>

        </div>
        <div class="row">

          <div class="col">
            <edit-footer
              :dirty="dirty"
              :disabled="item.published"
              :isNew="isNew"
              :submitting="submitting"
              :hide-delete="true"
              entity="weeklyMenu"></edit-footer>
          </div>

        </div>
      </form>
    </dimmer>

    <simulate-week
      :show.sync="showSimulateModal"
      :ingredients="missing"
      title="Can't publish week, Missing articles for portions in recipes"/>
  </div>
</template>

<script>

import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import SimulateWeek from '@/components/SimulateWeek';
import WeeklyMenusRecipeListCard from '@/components/view/weekly-menus/weekly-menus-recipe-list-card/index.vue';
import edit from '@/mixins/edit';
import simulateWeek from '@/mixins/simulateWeek';
import {weeklyMenus} from '@/services';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import moment from 'moment-timezone';

export default {
  components: {
    EditFooter,
    EditHeader,
    WeeklyMenusRecipeListCard,
    SimulateWeek,
  },
  mixins: [
    edit,
    simulateWeek,
  ],
  data() {
    return {
      item: {
        recipes: [],
      },
      startDate: thursdayBasedWeek(moment()).format('YYYY-MM-DD'),
    };
  },
  computed: {
    isSwitcherDisabled() {
      return (!this.item.id && !this.item.published) || !this.readyToBePublished || !this.isAllRecipes;
    },
    readyToBePublished() {
      return this.item.recipes.every(it => it.status === 'ready-to-be-drafted' || it.status === 'published-on-menu');
    },
    isAllRecipes() {
      return this.item.recipes.length >= 20;
    },
    startDateModel: {
      get() { return this.startDate; },
      set(newValue) { this.startDate = newValue; },
    },
    startDateIsThursday() {
      return moment(this.startDate).isoWeekday() === 4;
    },
  },
  watch: {
    'isAllRecipes': {
      handler(val) {
        if (!val) {
          this.item.published = false;
        }
      },
    },
    'readyToBePublished': {
      handler(val) {
        if (!val) {
          this.item.published = false;
        }
      },
    },
  },
  methods: {
    fetchData() {
      // run moment in strict and check date param validity
      if (moment(this.$route.params.id, 'YYYY-MM-DD', true).isValid()) {
        this.startDate = this.$route.params.id;
      }
      return weeklyMenus.fetch({startDate: this.startDate});
    },
    async submitData(item) {
      const payload = Object.assign({}, item, {
        recipes: {},
        startDate: this.startDate,
        status: item.published ? 'published' : 'scheduled',
      });

      payload.recipes = item.recipes.filter(it => !!it.id).map(it => ({recipe_id: it.id, number: it.number, is_default: it.is_default, availability: it.availability}));

      return weeklyMenus.saveOrUpdate(payload);
    },
    async switchPublished(item) {
      if (item.id && item.published && (await this.weekHasIssues(item))) { item.published = false; }
    },
    transformData(result) {
      if (this.submitting) {
        result.item = Object.assign(this.item, {status: result.item.status});
      }
      const mappedResult = {item: result.weeklyMenu ?? result.item};
      return {...mappedResult, item: {...mappedResult.item, published: mappedResult.item.status === 'published'}};
    },
    async validate() {
      return this.startDateIsThursday;
    },
  },
};

</script>
