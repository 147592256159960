<template>

  <div class="card">
    <div v-if="!!$slots.header" class="card-header">
      <h3 class="card-title"><slot name="header"/></h3>
      <div v-if="!!$slots.options" class="card-options">
        <slot name="options"/>
      </div>
    </div>
    <div style="max-height: 25rem; overflow-y: scroll; overflow-x: hidden;">
      <table class="table table-vcenter text-nowrap card-table">
        <tr v-for="item in items" :key="item.id">
          <td v-if="item.status !== 'ready-to-be-drafted' && item.status !== 'published-on-menu'" class="w-1">
            <i
              title="Recipe is not yet ready to be published"
              class="fe fe-warning fe-alert-triangle text-warning cursor pointer"
              aria-hidden="true"
              tooltip="Recipe is not yet ready to be published"></i>
          </td>
          <td>
            {{item.name}}
          </td>
          <td class="w-1">
            <router-link
              :to="goTo(item.id)"
              target="_blank"
              rel="noopener noreferrer">
              <i class="fe fe-external-link"></i>
            </router-link>
          </td>
        </tr>
        <tr v-if="!items.length">
          <td>There are no results found</td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>

export default {
  name: 'WeeklyMenusRecipeListCard',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goTo(id) {
      return `/recipes/${id}`;
    },
  },
};

</script>
