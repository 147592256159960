<template>
  <b-modal
    v-if="ingredients.length"
    v-model="safeShow"
    :scrollable="true"
    :title="title"
    size="lg"
    centered
    hide-footer>
    <template slot="modal-header-close"><wbr/></template>

    <div class="table-responsive" style="max-height: 30rem;">
      <table class="table card-table table-sm table-striped sticky-table">
        <thead class="thead-light">
          <tr>
            <th>Recipes</th>
            <th>Ingredient</th>
            <th class="text-center">Portion size</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ingredients" :key="index">
            <td><a :href="`/recipes/${item.recipe_id}`" target="_blank">{{item.recipe_name}}</a></td>
            <td><a :href="`/articles?query=${item.ingredient_name}`" target="_blank">{{item.ingredient_name}}</a></td>
            <td class="text-center">{{item.portion_size}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
    ingredients: {
      default: () => [],
      type: Array,
    },
    show: {
      default: false,
      type: Boolean,
    },
    title: {
      default: 'Missing articles for portions in recipes',
      type: String,
    },
  },
  computed: {
    safeShow: {
      get() { return this.show; },
      set(newValue) {
        this.$emit('update:show', newValue);
      },
    },
  },
};

</script>
