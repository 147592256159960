import {weeklyMenus} from '@/services';

export default {

  data() {
    return {
      error: false,
      invalid: false,
      missing: [],
      showSimulateModal: false,
      submitting: false,
    };
  },
  methods: {
    async weekHasIssues(week) {
      this.submitting = true;
      this.error = false;
      this.invalid = false;

      try {
        this.missing = (await weeklyMenus.simulate(week.id))?.missingPortions || [];

        if (this.missing.length) {
          this.invalid = true;
          this.showSimulateModal = true;
          return true;
        }

        this.invalid = false;
        return false;
      }
      catch (ex) {
        console.error(ex);
        this.error = true;
        return true;
      }
      finally {
        this.submitting = false;
      }
    },
  },
};
